import React, { createContext, useEffect, useState } from 'react';
import type { PropsWithChildren } from 'react';

interface ContextProps {
  lang: string;
  setLang: React.Dispatch<React.SetStateAction<string>>;
}

const LanguageContext = createContext<ContextProps>({
  lang: 'en',
  setLang: (prevStatus) => prevStatus,
});

const isServerSide = typeof window === 'undefined';

export function LanguageProvider({ children }: PropsWithChildren) {
  const [lang, setLang] = useState<string>(() => {
    const localLang = localStorage.getItem('lang');
    return localLang !== null ? localLang : 'en';
  });

  useEffect(() => {
    if (!isServerSide) {
      localStorage.setItem('lang', lang);
    }
  }, [lang]);

  return (
    <LanguageContext.Provider value={{ lang, setLang }}>
      {children}
    </LanguageContext.Provider>
  );
}

export default LanguageContext;
